import React, { useEffect } from 'react';
import { animateScroll as scroll, Link } from 'react-scroll';
import './Header.scss';

const Header = () => {
  const [isToggleOn, setIsToggleOn] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState('');


  useEffect(() => {
    window.addEventListener('hashchange', hashListener);
    return window.removeEventListener('hashchange', hashListener);
  });

  const hashListener = React.useCallback(
    () => setActiveMenu(window.location.hash),
    [],
  );

  const handleClickLink = React.useCallback(() => {
    scroll.scrollToTop();
  }, []);

  const menuActive = isToggleOn ? 'is-active' : '';
  return (
    <nav
      className='navbar is-fixed-top is-primary'
      role='navigation'
      aria-label='main navigation'
    >
      <div className='container'>
        <div className='navbar-brand'>
          <div
            className='navbar-burger'
            onClick={() => setIsToggleOn(!isToggleOn)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>

        <div className={'navbar-menu ' + menuActive}>
          <div className='navbar-start'>
            <div className='navbar-item'>
              <span className='icon'>
                <i className='fas fa-phone' />
              </span>
              <span>(+351) 219 267 270</span>
            </div>
            <div className='navbar-item'>
              <span className='icon'>
                <i className='fas fa-fax' />
              </span>
              <span>(+351) 219 267 278</span>
            </div>
            <div className='navbar-item'>
              <span className='icon'>
                <i className='fas fa-envelope' />
              </span>
              <span>geral@rioquimica.pt</span>
            </div>
          </div>
          <div className='navbar-end'>
            <Link
              to='us'
              activeClass='is-active'
              offset={-50}
              smooth={true}
              spy={true}
              className='navbar-item'
            >
              <span className='icon'>
                <i className='fas fa-search' />
              </span>
              <span>Sobre nós</span>
            </Link>

            <Link
              to='products'
              activeClass='is-active'
              offset={-50}
              smooth={true}
              spy={true}
              className='navbar-item'
            >
              <span className='icon'>
                <i className='fas fa-flask' />
              </span>
              <span>Os nossos produtos</span>
            </Link>
            <Link
              to='contacts'
              activeClass='is-active'
              offset={-50}
              smooth={true}
              spy={true}
              className='navbar-item'
            >
              <span className='icon'>
                <i className='fas fa-envelope' />
              </span>
              <span>Contacte-nos</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
